import React from "react";
import "./SelfPublishedPage.css";
import image from "./blog.jpg";
import PortfolioPagesHeader from "./PortfolioPagesHeader";
import PortfolioPagesFooter from "./PortfolioPagesFooter";

const SelfPublishedPage = (props) => {
  return (
    <div className="self-published-page">
      <PortfolioPagesHeader />
      <div className="self-published-page-header">
        <h1>SELF-PUBLISHED ONLINE/BLOG CONTENT</h1>
      </div>
      <div className="self-published-page-image-container">
        <img
          src={image}
          alt="scrabble letters spelling 'blog'"
          className="self-published-page-image"
        />
      </div>
      <div className="self-published-page-link-header">
        <h1>LINKS</h1>
      </div>
      <div className="self-published-page-links">
        <a href="https://medium.com/@charlotte.rachel.lewis/the-revolution-will-should-be-accessible-2559cd11317e">
          <div className="self-published-page-link">
            <h2>The Revolution Will (Should) Be Accessible - Medium Article</h2>
          </div>
        </a>
      </div>
      <PortfolioPagesFooter prev={props.prev} next={props.next} />
    </div>
  );
};

export default SelfPublishedPage;
