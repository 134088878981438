import React from "react";
import "./PortfolioPageHeader.css";
import { Link } from "react-router-dom";
import { UilArrowLeft } from "@iconscout/react-unicons";

const PortfolioPagesHeader = () => {
  return (
    <div className="portfolio-pages-header">
      <UilArrowLeft />
      <Link to="/portfolio">
        <h2>Return</h2>
      </Link>
    </div>
  );
};

export default PortfolioPagesHeader;
