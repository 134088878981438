import React from "react";
import "./FeaturesPage.css";
import PortfolioPagesHeader from "./PortfolioPagesHeader";
import PortfolioPagesFooter from "./PortfolioPagesFooter";

const FeaturesPage = (props) => {
  return (
    <div className="features-page">
      <PortfolioPagesHeader />
      <div className="features-page-header">
        <h1>REALITY TV FEATURES WRITER</h1>
      </div>
      <div className="features-page-blurb">
        <h1>
          My body of work with Screen Rant as a freelance reality TV features
          writer - A greate example of my entertainment analysis work
        </h1>
      </div>
      <a href="https://screenrant.com/author/charlie-lewis/">
        <div className="features-page-link">
          <h1>Link</h1>
        </div>
      </a>
      <PortfolioPagesFooter prev={props.prev} next={props.next} />
    </div>
  );
};

export default FeaturesPage;
