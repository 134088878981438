import React, { useState, useRef } from "react";
import { Alert, Button, TextField } from "@mui/material";
import emailjs from "@emailjs/browser";

const serviceId = "service_wicg56b";
const templateId = "template_bhdds1l";
const publicKey = "39JZy5jDyhcarBorY";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [formError, setFormError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const form = useRef();

  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const validate = (email) => {
    const expression =
      /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

    return expression.test(String(email).toLowerCase());
  };

  const pressSendBtn = () => {
    var canSend = true;
    if (name.trim() == "" || email.trim() == "" || message.trim() == "") {
      setFormError(true);
      canSend = false;
    } else {
      setFormError(false);
      setIsSent(false);
    }
    if (!validate(email)) {
      setEmailError(true);
      canSend = false;
    } else {
      setEmailError(false);
      setIsSent(false);
    }

    if (canSend) {
      sendEmail();
      setIsSent(true);
    } else {
      setIsSent(false);
    }
  };

  const sendEmail = () => {
    var params = {
      reply_to: email,
      from_name: name,
      message: message,
    };

    emailjs.send(serviceId, templateId, params, publicKey).then(
      function (response) {
        console.log("Success", response.status, response.text);
      },
      function (error) {
        console.log("Failed", error);
      }
    );
  };

  const drawAlert = () => {
    return <Alert severity="error">All contents must be filled out</Alert>;
  };
  const drawEmailAlert = () => {
    return <Alert severity="error">A valid email address must be used</Alert>;
  };
  const drawSuccessBanner = () => {
    return <Alert severity="success">Sent!</Alert>;
  };

  return (
    <div className="contact-form">
      <div className="contact-form-info">
        <div className="info-input">
          <TextField
            required
            id="outlined-required"
            label="Name"
            sx={{ m: 1 }}
            InputLabelProps={{ m: 1 }}
            onChange={handleNameChange}
            name="from_name"
          />
        </div>
        <TextField
          required
          sx={{ p: 1 }}
          onChange={handleEmailChange}
          id="outlined-required"
          label="Email"
        />
      </div>
      <div className="contact-page-form-message">
        <TextField
          required
          sx={{ p: 1 }}
          onChange={handleMessageChange}
          multiline
          id="outlined-multiline-flexible"
          label="message"
          name="message"
          rows={4}
          style={{ width: "400px" }}
          maxRows={Infinity}
        />
      </div>
      <div className="btn">
        <Button
          onClick={pressSendBtn}
          sx={{
            color: "black",
          }}
        >
          Send
        </Button>
      </div>
      {formError && drawAlert()}
      {emailError && drawEmailAlert()}
      {isSent && drawSuccessBanner()}
    </div>
  );
};

export default ContactForm;
