import React, { useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCamera } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { UilInstagramAlt } from "@iconscout/react-unicons";

const hamburgerStyle = {
  textDecoration: "none",
  fontSize: "30px",
  color: "black",
};

const linkStyle = {
  textDecoration: "none",
  fontSize: "24px",
  fontFamily: "Bigshot One",
};

const HamburgerMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={hamburgerStyle}
      >
        <div className="button">
          <FontAwesomeIcon icon={faBars} />
        </div>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          backgroundColor: "bisque",
        }}
      >
        <Link style={linkStyle} to="/portfolio">
          <MenuItem style={linkStyle}>Portfolio</MenuItem>
        </Link>
        <Link style={linkStyle} to="/about">
          <MenuItem style={linkStyle}>About</MenuItem>
        </Link>
        <Link style={linkStyle} to="/contact">
          <MenuItem style={linkStyle}>Contact</MenuItem>
        </Link>
        <a href="https://www.instagram.com/badbottleproductions/">
          <MenuItem style={linkStyle}>
            Instagram
            <UilInstagramAlt />
          </MenuItem>
        </a>
      </Menu>
    </div>
  );
};

export default HamburgerMenu;
