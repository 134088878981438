import React from "react";
import Marquee from "react-fast-marquee";
import "./TextScroll.css";
import { UilAnalysis } from "@iconscout/react-unicons";

const TextScroll = () => {
  return (
    <Marquee gradient={false} className="text-scroll-marquee">
      <div className="text-scroll" gradient={false}>
        <></>
        <h2>Screenwriting</h2>
        <></>
        <UilAnalysis />
        <h2>Entertainment</h2>
        <></>
        <UilAnalysis />
        <h2>Film</h2>
        <></>
        <UilAnalysis />
        <h2>Blogs</h2>
        <></>
        <UilAnalysis />
        <h2>Articles</h2>
        <></>
        <UilAnalysis />
        <></>
      </div>
    </Marquee>
  );
};

export default TextScroll;
