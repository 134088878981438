import React from "react";
import { Link } from "react-router-dom";
import "./PortfolioPagesFooter.css";
import { UilArrowLeft, UilArrowRight } from "@iconscout/react-unicons";

const PortfolioPagesFooter = (props) => {
  const prevLink = () => {
    return (
      <div className="portfolio-pages-footer-link">
        <UilArrowLeft />
        <Link to={"/portfolio/" + props.prev.link}>
          <h2>{props.prev.text}</h2>
        </Link>
      </div>
    );
  };

  const nextLink = () => {
    return (
      <div className="portfolio-pages-footer-link">
        <Link to={"/portfolio/" + props.next.link}>
          <h2>{props.next.text}</h2>
        </Link>
        <UilArrowRight />
      </div>
    );
  };
  return (
    <div className="portfolio-pages-footer">
      {props.prev != null ? prevLink() : <div></div>}
      {props.next != null ? nextLink() : <></>}
    </div>
  );
};

export default PortfolioPagesFooter;
