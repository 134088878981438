import React from "react";
import "./Home.css";
import TextScroll from "./TextScroll";

const Home = () => {
  return (
    <div className="homepage">
      <div className="homepage-container">
        <TextScroll />
        <div className="homepage-contents">
          <div className="homepage-text-content">
            <h2>
              Welcome! My name is Charlie Lewis (he/him/his) and I am a writer
              and filmmaker available for freelance opportunities and creative
              collaboration. Let's make something amazing together!
            </h2>
          </div>
          <img
            className="homepage-image"
            src="/typewriter.jpg"
            alt="typewriter"
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
