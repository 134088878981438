import React from "react";
import "./PortfolioCard.css";
import { Link } from "react-router-dom";

const PortfolioCard = (props) => {
  return (
    <Link to={props.content.link}>
      <div className="portfolio-card">
        <img
          className="portfolio-card-image"
          src={props.content.thumbnail}
          alt={props.content.thumbnail}
        />
        <div className="portfolio-card-overlay">
          <h1 className="portfolio-card-desc">{props.content.text}</h1>
        </div>
      </div>
    </Link>
  );
};

export default PortfolioCard;
