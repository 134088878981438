import "./App.css";
import Home from "./components/Home";
import { Route, Routes } from "react-router-dom";
import AboutPage from "./components/AboutPage";
import SiteHeader from "./components/SiteHeader";
import Portfolio from "./components/Portfolio";
import ContactPage from "./components/ContactPage";
import FeaturesPage from "./components/PortfolioPages/FeaturesPage";
import StudentFilmPage from "./components/PortfolioPages/StudentFilmPage";
import PortfolioSplashPage, {
  PortfolioPages,
} from "./components/PortfolioPages/PortfolioSplashPage";
import BlogsPage from "./components/PortfolioPages/BlogsPage";
import ClientsPage from "./components/PortfolioPages/ClientsPage";
import SelfPublishedPage from "./components/PortfolioPages/SelfPublishedPage";
import SiteFooter from "./components/SiteFooter";

function App() {
  return (
    <div className="app">
      <SiteHeader />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="portfolio" element={<Portfolio />}>
          <Route index element={<PortfolioSplashPage />} />
          <Route
            path={PortfolioPages.FEATURES.link}
            element={<FeaturesPage prev={null} next={PortfolioPages.CLIENTS} />}
          />
          <Route
            path={PortfolioPages.CLIENTS.link}
            element={
              <ClientsPage
                prev={PortfolioPages.FEATURES}
                next={PortfolioPages.BLOGS}
              />
            }
          />
          <Route
            path={PortfolioPages.BLOGS.link}
            element={
              <BlogsPage
                prev={PortfolioPages.CLIENTS}
                next={PortfolioPages.STUDENT_FILM}
              />
            }
          />
          <Route
            path={PortfolioPages.STUDENT_FILM.link}
            element={
              <StudentFilmPage
                prev={PortfolioPages.BLOGS}
                next={PortfolioPages.SELF_PUBLISHED}
              />
            }
          />
          <Route
            path={PortfolioPages.SELF_PUBLISHED.link}
            element={
              <SelfPublishedPage
                prev={PortfolioPages.STUDENT_FILM}
                next={null}
              />
            }
          />
        </Route>
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
      </Routes>
      <SiteFooter />
    </div>
  );
}

export default App;
