import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./SiteHeader.css";
import HamburgerMenu from "./HamburgerMenu";
import { UilInstagramAlt } from "@iconscout/react-unicons";

const linkStyle = {
  textDecoration: "none",
  fontSize: "18px",
  color: "black",
};

const windowMinSize = 900;

const SiteHeader = () => {
  const [isExpanded, setIsExpanded] = useState(
    window.innerWidth >= windowMinSize
  );

  const isWindowExpanded = () => {
    setIsExpanded(window.innerWidth >= windowMinSize);
    return isExpanded;
  };

  useEffect(() => {
    window.addEventListener("resize", isWindowExpanded);
  }, []);

  const wideHeader = () => {
    return (
      <>
        <div className="nav-item">
          <span>
            <div className="links">
              <Link to="/portfolio" style={linkStyle}>
                <div className="link">
                  <h2>Portfolio</h2>
                </div>
              </Link>
              <div className="link">
                <Link to="/about" style={linkStyle}>
                  <h2>About</h2>
                </Link>
              </div>
              <div className="link">
                <Link to="/contact" style={linkStyle}>
                  <h2>Contact</h2>
                </Link>
              </div>
            </div>
          </span>
        </div>
        <div className="nav-item">
          <div className="title-container">
            <span>
              <Link to="/" style={linkStyle}>
                <div className="title">
                  <h1
                    style={{
                      paddingRight: "10px",
                      fontStretch: "condensed",
                    }}
                  >
                    CHARLIE LEWIS WRITES
                  </h1>
                </div>
              </Link>
            </span>
          </div>
        </div>
        <div className="nav-item">
          <span>
            <a
              href="https://www.instagram.com/badbottleproductions/"
              style={linkStyle}
              className="links"
            >
              <div className="link">
                <UilInstagramAlt />
              </div>
            </a>
          </span>
        </div>
      </>
    );
  };

  const narrowHeader = () => {
    return (
      <>
        <div className="nav-item">
          <span>
            <HamburgerMenu />
          </span>
        </div>
        <div className="nav-item">
          <div className="title-container">
            <Link to="/" style={linkStyle}>
              <div className="title">
                <h1 style={{ paddingRight: "10px" }}>Charlie Lewis Writes</h1>
              </div>
            </Link>
          </div>
        </div>
        <div className="nav-item">
          <span></span>
        </div>
      </>
    );
  };

  return (
    <div className="site-header">
      <div className="base-header">
        {isExpanded ? wideHeader() : narrowHeader()}
      </div>
    </div>
  );
};

export default SiteHeader;
