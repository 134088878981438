import React from "react";
import "./AboutPage.css";

const AboutPage = () => {
  return (
    <div className="about-page">
      <div className="about-page-container">
        <div className="about-page-text">
          <h1 className="about-page-header">MY STORY</h1>
          <p>
            I'm a non-binary writer currently based out of Portland, OR.
            However, I grew up accross the country, just south of the "other"
            Portland in Maine! I began my writing career at 5 years old when I
            wrote my first book of poetry. Since then I have become a
            classically trained actor, produced playwright, comic, freeland
            content and entertainment writer, and a writer/director/filmmaker. I
            studied dramatic writing, literature, filmmaking, psychology, and
            theatre at Sarah Lawrence College in Bronxville, NY, from which I
            graduated in 2017 with a BA.
          </p>
          <p>
            Since graduating I have utilized my liberal arts education to teach,
            write, and help others realize their creative visions.
          </p>
          <p>
            I enjoy writing blogs, articles, and other forms of online content.
            I love film, television, books, and most story-telling mediums! My
            connection to the past and current landscape of media and culture
            allows me to excel at reporting, analyzing, and creating work
            relevant to entertainment.
          </p>
          <p>
            As a queer and trans writer, creating content that is connected to
            queerness, that allows others to feel seen is very important to me.
            This perspective informs all of my work, regardless of the subject!
            This is one of the benefits of working with me.
          </p>
          <p>
            I would love to collaborate with you and create something Unique and
            awesome!
          </p>
        </div>
        <div>
          <img
            className="about-page-image"
            src="charlie-face.jpeg"
            alt="face"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
