import React from "react";
import "./BlogsPage.css";
import PortfolioPagesFooter from "./PortfolioPagesFooter";
import PortfolioPagesHeader from "./PortfolioPagesHeader";

const BlogsPage = (props) => {
  return (
    <div className="blogs-page">
      <PortfolioPagesHeader />
      <div className="blogs-page-header">
        <h1>
          A greate example of my blog post writing; this piece was commissioned
          for a local dating service in Portland, OR
        </h1>
      </div>
      <a href="http://ferndate.com/blog/dating-apps/common-dating-profile-acronyms/">
        <div className="blogs-page-content">
          <h1>Decoding Dating: Common Dating Profile Acronyms</h1>
        </div>
      </a>
      <PortfolioPagesFooter prev={props.prev} next={props.next} />
    </div>
  );
};

export default BlogsPage;
