import React from "react";
import "./ClientsPage.css";
import PortfolioPagesHeader from "./PortfolioPagesHeader";
import PortfolioPagesFooter from "./PortfolioPagesFooter";

const ClientsPage = (props) => {
  return (
    <div className="clients-page">
      <div className="portfolio-header">
        <PortfolioPagesHeader />
      </div>
      <div className="clients-page-header">
        <h1>CURRENT & PREVIOUS CLIENTS</h1>
      </div>
      <div className="clients-page-content-container">
        <div className="clients-page-content">
          <h1>SCREEN RANT</h1>
        </div>
        <div className="clients-page-content">
          <h1>NORTHWEST CHILDREN'S THEATRE AND SCHOOL</h1>
        </div>
        <div className="clients-page-content">
          <h1>FERNDATE</h1>
        </div>
        <div className="clients-page-content">
          <h1>MOVIE MAKING THROUGHOUT THE BAY</h1>
        </div>
      </div>
      <PortfolioPagesFooter prev={props.prev} next={props.next} />
    </div>
  );
};

export default ClientsPage;
