import React from "react";
import ContactForm from "./ContactForm";
import "./ContactPage.css";

const ContactPage = () => {
  return (
    <div className="contact-page">
      <div className="contact-page-container">
        <div className="contact-page-content">
          <h1 className="contact-page-header">CONTACT</h1>
          <h3>
            Interested in hiring me for creative work? Please don't hesitate to
            reach out via email, phone, or text!
          </h3>
          <h4>charlielewiswrites@gmail.com</h4>
          <h4>(207) 450-8526</h4>
        </div>
        <div className="contact-page-form">
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
