import React from "react";
import "./SiteFooter.css";

const SiteFooter = () => {
  return (
    <div className="site-footer">
      <small>
        Designed, coded, and produced by{" "}
        <a
          className="site-footer-link"
          href="https://www.instagram.com/whistlepunk.labs/"
        >
          @Whistlepunk.labs
        </a>
      </small>
    </div>
  );
};

export default SiteFooter;
